import Slider from "react-slick";
import bannerSlide1 from "../assets/images/cashback-slider-img-1.png";
import React, { useEffect, useState } from "react";

const BannerSlider = ({data}) => {
  const [sliderData, setSliderData] = useState([]);

  const bannerSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <section className="banner-slider">
       
        <Slider {...bannerSlider}>
        {data?.map((item, index) => (
          <div className="img-wrapper" key={index}>
            <img src={item} alt="Banner Slider" />
          </div>
        ))}
        </Slider>
      </section>
    </>
  );
};

export { BannerSlider };
