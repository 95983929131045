import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../components/loading";
import { BrandHeader } from "../components/Header";
import Footer from "../components/Footer";
import schemaProductImg from "../assets/images/schema-product-img.png";
import { TermsCondition } from "../components/TermsCondition";
import { OfferTracker } from "../components/SocialLinks";
import { BASE_URL, WEB_URL, MOBILE_URL} from "../Constants";
import { getTokens } from "../helpers/helpers";
import moment from "moment";
import Accordion from "react-bootstrap/Accordion";
import LZString from "lz-string";
import 'moment-timezone';
function ComboOfferDetail() {
  const { schemeId } = useParams();
  console.log(schemeId);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [termCondition, setTermCondition] = useState('');
  const [filterBy, setFilterBy] = useState("");
  const [mobile, setMobile] = useState(window.innerWidth <= 700);
  const [showOrderNowCta, setOrderNowCta] = useState(true);
  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  useEffect(() => {
    console.log("Call");
    getRecentOrder();
  }, [filterBy.length]);
  const getRecentOrder = async () => {
    setIsLoading(true);
    try {
      let ep = `${BASE_URL}/web-sdk/api/schemes/${schemeId}/cashback_details`
      const response = await fetch(
        ep,
        {
          method: "GET",
          headers: {
            "X-Token": getTokens()?.token,
            "X-Access-Token": getTokens()?.pid,
          },
        }
      );
      const json = await response.json();
      setFilterBy(json.scheme_details);
      const mapObj = {
        strong: `strong style="font-size:14px"`,
        p:`p style="font-size:14px"`,
        'ul li': `ul li list-style:inside`,
        
      };
      var str = json?.scheme_details?.tnc.replace(/\b(?:strong|p|ul)\b/gi, matched => mapObj[matched]);
      const step = LZString.decompressFromEncodedURIComponent(json.scheme_details.tnc);
      // const steparr = step.split(".");
      // setTermCondition(<div dangerouslySetInnerHTML={{ __html:  json?.scheme_details?.tnc}} />)
      setTermCondition(str);
      console.log(json, "ok");
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    window.mobileCheck = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    };
    setMobile(window.mobileCheck);
  }, []);


  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <BrandHeader
            pageTitle={filterBy.scheme_name}
            pageDes={
              "Hurry Up.!! Valid till " +
              moment(filterBy.ends_at).tz('America/New_York').format("LL")
            }
            image="schema-header-img.png"
          />
          <section className="schema-product">
            <div className="container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <div
                  className="comb-img"
                  style={{ overflowX: "auto" }}
                >
                  <div className="img-wrapper-container">
                    {filterBy &&
                      filterBy?.products?.map((item, i) => (
                        <div >
                          <div
                            style={{ margin: "10px" }}
                            className="product-img"
                            key={i}
                          >
                            <img src={`${item?.logo}`} alt="Product" />

                          </div>
                          <div style={{ margin: "10px" }}>MRP : ₹{item.ptr}</div>
                        </div>
                      ))}

                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="schema-offer-track">
            <div className="container">
              <h3>Offer Tracker</h3>
              <OfferTracker data={filterBy} />
              <div className="terms-condition">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    <p style={{ whiteSpace: "nowrap",overflow:"hidden"}}>Terms & Conditions</p>
                    </Accordion.Header>
                    <Accordion.Body>                     
                        <div dangerouslySetInnerHTML={{ __html: termCondition }} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div style={{marginTop:"15px",marginBotton:"15px",display:"flex",alignItems:"center",justifyContent:"center",width:"100%"}}>
              {showOrderNowCta && <a href={mobile ? MOBILE_URL : WEB_URL} className="btn-style medium" target={mobile ? "_blank" : "_blank"}>
                ORDER NOW 
              </a>}
              </div>
              
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
}
export default ComboOfferDetail;
