import productsDealIcon from "../assets/images/icons/products-deal-icon.svg";
import { Link } from "react-router-dom";
import comboDealIcon from "../assets/images/icons/combo-deal-icon.svg";
import giftIcon from "../assets/images/icons/gift-card-icon.svg";
import React from "react";
import { getTokens } from "../helpers/helpers";

const DealFilter = (props) => {
    const openFlyySDK = async () => {
        
        // var env = "development";
        var env = "PRODUCTION"
        const modalOverlay = document.createElement("div");
        modalOverlay.id = "modal-overlay";
        modalOverlay.style.position = "fixed";
        modalOverlay.style.top = "0";
        modalOverlay.style.left = "0";
        modalOverlay.style.width = "100%";
        modalOverlay.style.height = "100%";
        modalOverlay.style.backgroundColor = "rgba(0, 0, 0, 0.9)";
        modalOverlay.style.display = "flex";
        modalOverlay.style.alignItems = "center";
        modalOverlay.style.justifyContent = "center";
        modalOverlay.style.zIndex = 99999999;
        const iframe = document.createElement("iframe");
        iframe.src = `https://flyy-web-sdk.vercel.app/?p_id=${getTokens().pid}&token=${getTokens().token}&env=${env}`;
        // iframe.src = `http://localhost:8080/?p_id=${getTokens().pid}&token=${getTokens().token}&env=${env}`;
        iframe.id = "iframe"
        iframe.style.width = "100%";
        iframe.style.height = "100%";
        modalOverlay.appendChild(iframe);
        document.body.appendChild(modalOverlay);

        window.addEventListener('message', (event) => {
            if (event.data === 'close-sdk') {
                const iframe = document.getElementById('iframe');
                if (iframe) {
                    iframe.parentNode.removeChild(iframe);
                }
                const modalOverlay = document.getElementById('modal-overlay');
                if (modalOverlay) {
                    modalOverlay.parentNode.removeChild(modalOverlay);
                }
            }
        });
    };

    return (
        <>
            <div className="deal-filter">
                <Link to="/product-offer" className="btn-style medium transparent blue rounded icon">
                    <img src={`${productsDealIcon}`} alt="Product" />
                    Product Deals
                </Link>
                <Link to="/combo-offer" className="btn-style medium transparent blue rounded icon">
                    <img src={`${comboDealIcon}`} alt="Product" />
                    Combo Deals
                </Link>
                {/* <Link onClick={openFlyySDK} className="btn-style medium transparent blue rounded icon">
                    <img src={`${giftIcon}`} alt="Product" />
                    Offers
                </Link> */}
            </div>
        </>
    );
};

export { DealFilter };
